/* Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import CategoricalScheme from '../../CategoricalScheme';

const schemes = [
  {
    id: 'industrygenomeColors',
    label: 'IndustryGenome Colors',
    colors: [
      // Full color
      // '#005C7D',
      "#DDF3F8",
      "#C8C8C8",
      "#36B0C9",
      "#08748A",
      "#54565A",
      "#341936",
    ],
  },
  {
    id: 'industrygenomeColors_inverted',
    label: 'IndustryGenome Colors Inverted',
    colors: [
     "#341936",
      "#54565A",
      "#08748A",
      "#36B0C9",
      "#C8C8C8",
      "#DDF3F8",
    ],
  }
].map(s => new CategoricalScheme(s));

export default schemes;
